body {
  margin: 0; padding: 0;  
  background-color: #27292A;
}

h1, h2, h3, h4, h5, p, li, a, div, span, section{
  font-family: 'Noto Sans Cham', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
  padding: 0px; margin:0px;
}

.yellowColor{
  color: #FDC500;
}