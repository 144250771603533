.logo {
    text-align: center;
  }
  
  .logoSection {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .logoSection h1{
    font-size: 36px;
  }

  .sloganText{
    font-size: 18px;
    color: white;    
    margin: 32px auto 32px auto;
  }

  .downloadIconsList{
    margin:20px; padding: 0px;
    list-style-type: none;
  }

  .downloadIconsList li{
    display: inline;
    margin: 10px 5px;
  }

  .downloadIconsList li a img{
    width: 180px; height: 53px;
  }